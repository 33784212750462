import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClientsSpeakComponent } from '@components/clients-speak/clients-speak.component';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { OurContactsComponent } from '@components/our-contacts/our-contacts.component';
import { StatisticComponent } from '@components/statistic/statistic.component';
import { BACKGROUND_COLORS } from '@const/backgroud-colors.const';
import { Person } from '@interfaces/person.interface';
import { map, Observable } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company',
  standalone: true,
  imports: [
    StatisticComponent,
    OurContactsComponent,
    ClientsSpeakComponent,
    ContactUsComponent,
    RouterModule,
  ],
  templateUrl: './company.component.html',
  styleUrl: './company.component.scss',
})
export class CompanyComponent {
  constructor(private dataService: DataService) {}

  public persons!: Observable<Person[]>;
  public chiefPhotoUrl =
    environment.staticUrl + '/resources/pixel/images/chief-image.jpg';

  ngOnInit(): void {
    const lang: string | null = localStorage.getItem('lang');
    if (lang) {
      this.persons = this.dataService.getFilteredPersons(lang, 'Sales').pipe(
        map((persons) =>
          persons.map((person, index) => ({
            ...person,
            color:
              index < BACKGROUND_COLORS.length
                ? BACKGROUND_COLORS[index]
                : BACKGROUND_COLORS[
                    index -
                      Math.floor(index / BACKGROUND_COLORS.length) *
                        BACKGROUND_COLORS.length
                  ],
          }))
        )
      );
    }
  }
}
