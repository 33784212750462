<div class="company">
  <div class="header-main">
    <div class="header-main__text">
      <h2 i18n="@@We build *span*Solutions*span* that *span*Shape Tomorrow*span*">
        We build <span>Solutions</span>
        <br>
        that <span>Shape Tomorrow</span>
      </h2>
      <div>
        <p class="l normal" i18n="@@We specialize in the outstaff model, helping companies implement projects when they lack their own resources.">We specialize in the outstaff model, helping companies implement projects when they lack their own resources.<br></p>
        <p class="l normal" i18n="@@Our company specializes in developing web and cross-platform (mobile) applications. We handle projects for businesses from inception to completion.">Our company specializes in developing web and cross-platform (mobile) applications. We handle projects for businesses from inception to completion.<br></p>
      </div>
    </div>
    <div class="background">
      <h1 i18n="@@Company">Company</h1>
    </div>
  </div>

  <div class="company__welcome">
    <div class="welcome-text">
      <h2 i18n="@@Welcome">Welcome</h2>
      <div class="content">
        <p class="l normal" i18n="@@El Pixel is the guarantor of honesty and experience in the web and cross-platform application development. We are committed to delivering exceptional solutions that drive innovation and success for our clients.">El Pixel is the guarantor of honesty and experience in the web and cross-platform application development. We are committed to delivering exceptional solutions that drive innovation and success for our clients.<br></p>
        <p class="l normal" i18n="@@We are proud of our team of skilled frontend, backend, and machine learning developers. Explore our services and discover how we can help you achieve your goals.">We are proud of our team of skilled frontend, backend, and machine learning developers. <br><br>Explore our services and discover how we can help you achieve your goals.<br></p>
        <p class="l normal" i18n="@@We look forward to partnering with you.">We look forward to partnering with you.</p>
      </div>
      <p class="l bold" i18n="@@Sergey Novik & Vadim Hrushyn, Co-Founders">Sergey Novik & Vadim Hrushyn, Co-Founders</p>
      <div class="action">
        <button class="button-primary l" routerLink="/contact-us" i18n="@@Contact us">Contact us<br></button>
      </div>
    </div>
    <div
      class="welcome-image border-custom middle"
      [style.background-image]="'url(' + chiefPhotoUrl + ')'"
    ></div>
  </div>
  <div class="company__statistic">
    <app-statistic [showButton]="false"></app-statistic>
  </div>
  <div class="company__perks">
    <div class="company__perks__mission">
      <div>
        <h2 i18n="@@Vision">Vision</h2>
        <p class="l normal" i18n="@@At El-Pixel, we envision a future where technology seamlessly integrates with everyday life, empowering businesses to innovate and thrive. We strive to be the trusted partner that turns visionary ideas into impactful digital solutions.">At El-Pixel, we envision a future where technology seamlessly integrates with everyday life, empowering businesses to innovate and thrive. We strive to be the trusted partner that turns visionary ideas into impactful digital solutions.<br></p>
      </div>
      <div>
        <h2 i18n="@@Mission">Mission</h2>
        <p class="l normal" i18n="@@We aim to assist our clients in realizing their projects by delivering high-quality IT solutions. Our goal is to offer efficient and innovative software development solutions while maintaining fair pricing and high professionalism.">We aim to assist our clients in realizing their projects by delivering high-quality IT solutions. Our goal is to offer efficient and innovative software development solutions while maintaining fair pricing and high professionalism.<br></p>
      </div>
    </div>
    <div class="company__perks__values">
      <h2 i18n="@@Values">Values</h2>
      <div class="values">
        <div class="values__wrapper">
          <div class="values__wrapper__block">
            <h4 i18n="@@Quality">Quality</h4>
            <p class="l normal" i18n="@@We are committed to delivering excellence in every project, ensuring that our solutions meet the highest industry standards.">We are committed to delivering excellence in every project, ensuring that our solutions meet the highest industry standards.<br></p>
          </div>

          <div class="values__wrapper__block">
            <h4 i18n="@@Integrity">Integrity</h4>
            <p class="l normal" i18n="@@Honesty is at the core of our business. We value transparency and ethical conduct in all our interactions with clients and partners.">Honesty is at the core of our business. We value transparency and ethical conduct in all our interactions with clients and partners.<br></p>
          </div>

          <div class="values__wrapper__block">
            <h4 i18n="@@Innovation">Innovation</h4>
            <p class="l normal" i18n="@@We strive to stay ahead of technological advancements and trends, continually improving our skills and services to offer innovative solutions.">We strive to stay ahead of technological advancements and trends, continually improving our skills and services to offer innovative solutions.<br></p>
          </div>
        </div>

        <div class="values__wrapper">
          <div class="values__wrapper__block">
            <h4 i18n="@@Collaboration">Collaboration</h4>
            <p class="l normal" i18n="@@We believe in the power of teamwork and collaboration. By fostering a supportive and inclusive work environment, we encourage creativity and shared success.">We believe in the power of teamwork and collaboration. By fostering a supportive and inclusive work environment, we encourage creativity and shared success.<br></p>
          </div>

          <div class="values__wrapper__block">
            <h4 i18n="@@Adaptability">Adaptability</h4>
            <p class="l normal" i18n="@@In the ever-evolving world of technology, we remain flexible and adaptable, ensuring we can meet the changing needs of our clients and the industry.">In the ever-evolving world of technology, we remain flexible and adaptable, ensuring we can meet the changing needs of our clients and the industry.<br></p>
          </div>

          <div class="values__wrapper__block">
            <h4 i18n="@@Customer-Centricity">Customer-Centricity</h4>
            <p class="l normal" i18n="@@Our dedication to customer-centricity drives every decision and action we take, fostering long-lasting relationships built on trust and mutual success.">Our dedication to customer-centricity drives every decision and action we take, fostering long-lasting relationships built on trust and mutual success.<br></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="company_contacts">
    <app-our-contacts titleName="sales" [persons]="persons"></app-our-contacts>
  </div>
  <div class="company__join">
    <div class="company__join__text">
      <h2 i18n="@@Join Our Team">Join Our Team</h2>
      <div class="action">
        <p class="l normal" i18n="@@We’re always looking for talented individuals to join our dynamic team. Explore exciting career opportunities and become part of a company that values innovation and growth.">We’re always looking for talented individuals to join our dynamic team. Explore exciting career opportunities and become part of a company that values innovation and growth.<br></p>
        <button routerLink="/company/career" class="button-primary l" i18n="@@Explore Careers">Explore Careers</button>
      </div>
    </div>
    <div class="company__join__background"></div>
  </div>
  <div class="company__clients-speak">
    <app-clients-speak></app-clients-speak>
  </div>
  <div class="company__contact-us">
    <app-contact-us></app-contact-us>
  </div>
</div>
